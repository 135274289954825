export default {
  header_logoName: "",
  header_day: "",
  header_question: "",
  header_modul: "",
  header_text: "",
  buttonHideTheory_text: "",
  congratsModal_title: "",
  congratsModal_text1_1: "",
  congratsModal_text1_2: "",
  congratsModal_text2: "",
  congratsModal_text3: "",
  congratsModal_text5_1: "",
  congratsModal_text5_2: "",
  congratsModal_text5_3: "",
  congratsModal_text5_4: "",
  congratsModal_text5_5: "",
  congratsModal_text5_6: "",
  congratsModal_text5_7: "",
  congratsModal_text5_8: "",
  congratsModal_text6_1: "",
  congratsModal_text6_2: "",
  congratsModal_text6_3: "",
  congratsModal_text6_4: "",
  congratsModal_text6_5: "",
  congratsModal_text6_6: "",
  congratsModal_text6_7: "",
  congratsModal_text7_1: "",
  congratsModal_text7_2: "",
  congratsModal_text7_3: "",
  congratsModal_text7_4: "",
  congratsModal_text7_5: "",
  congratsModal_text7_6: "",
  congratsModal_text7_7: "",
  congratsModal_text7_8: "",
  congratsModal_text8_1: "",
  congratsModal_text8_2: "",
  congratsModal_text8_3: "",
  congratsModal_text8_4: "",
  congratsModal_text8_5: "",
  congratsModal_text8_6: "",
  congratsModal_title_final: "",
  congratsModal_text9_1: "",
  congratsModal_text9_2: "",
  congratsModal_text9_3: "",
  congratsModal_text9_4: "",
  congratsModal_text9_5: "",
  congratsModal_text9_6: "",
  congratsModal_text9_7: "",
  congratsModal_text9_8: "",
  congratsModal_text9_9: "",
  congratsModal_text9_10: "",
  congratsModal_text9_11: "",
  ErrorInvalidTokenOrBlock_text: "",
  ErrorServerRefresh_text: "",
  ErrorServerStub_text: "",
  ErrorStub_text: "",
  footer_text: "",
  MobileAndTabletStub_text: "",
  ModalAutotranslator_title: "",
  ModalAutotranslator_text: "",
  ModalRefreshQuestion_title: "",
  ModalRefreshQuestion_yes: "",
  ModalRefreshQuestion_no: "",
  ModalTaskCriteria_title: "",
  ModalTaskCriteria_success: "",
  ModalTaskCriteria_errors: "",
  ModalTaskCriteria_buttonContinue: "",
  ModalTaskCriteria_buttonNextQuestion: "",
  ModalTaskCriteria_buttonOk: "",
  TaskButtons_btnCheck: "",
  TaskButtons_btnRefresh: "",
  markup: "",
  click_picture: "",
  Timer_text: "",
  callToastify_taskAlreadySolved: "",
  callToastify_text1: "",
  callToastify_text2: "",
  callToastify_text3: "",
  callToastify_text4: "",
  callToastify_text5: "",
  callToastify_text6: "",
  selectCurrentQuestion_text: "",
  modalPolitics_title: "",
  modalPolitics_text: "",
  modalPolitics_subtitle1: "",
  modalPolitics_text1_1: "",
  modalPolitics_text1_2: "",
  modalPolitics_text1_3: "",
  modalPolitics_subtitle2: "",
  modalPolitics_text2_1: "",
  modalPolitics_subtitle3: "",
  modalPolitics_text3_1: "",
  ScoreRate_text: "",
  ScoreRateTooltip: "",
  LivelihoodIndicatorTooltip: "",
  TaskButtonsVideo_tooltip: "",
  LeftSidePanel_title: "",
  LeftSidePanel_progress: "",
  LeftSidePanel_level: "",
  LeftSidePanel_bonus: "",
  LeftSidePanel_nextLevel: "",
  bonus: "",
  videoLinksToBonus_bonusSoftSkills: "",
  videoLinksToBonus_bonusFindJob: "",
  videoLinksToBonus_linkedin: "",
  LeftSidePanel_inviteFriend: "",
  LeftSidePanel_lifes: "",
  ModalIntroductionInfo_title: "",
  ModalIntroductionInfo_text1: "",
  ModalIntroductionInfo_text0: "",
  ModalIntroductionInfo_text2_1: "",
  ModalIntroductionInfo_text2_2: "",
  ModalIntroductionInfo_text2_3: "",
  ModalIntroductionInfo_text3: "",
  ModalIntroductionInfo_text4: "",
  ModalIntroductionInfo_text4_1: "",
  ModalIntroductionInfo_text4_2: "",
  ModalIntroductionInfo_text5: "",
  ModalIntroductionInfo_text6: "",
  ModalIntroductionInfo_text7: "",
  ModalIntroductionInfo_text7_1: "",
  ModalIntroductionInfo_text7_2: "",
  ModalIntroductionInfo_text8_1: "",
  ModalIntroductionInfo_text8_2: "",
  ModalIntroductionInfo_text8_3: "",
  ModalIntroductionInfo_text8_4: "",
  ModalIntroductionInfo_text8_5: "",
  ModalIntroductionInfo_text8_6: "",
  ModalIntroductionInfo_text8_7: "",
  ModalIntroductionInfo_text9: "",
  ModalIntroductionInfo_text10: "",
  ModalIntroductionInfo_text10_1: "",
  ModalIntroductionInfo_text10_2: "",
  ModalIntroductionInfo_text10_3: "",
  ModalIntroductionInfo_text10_4: "",
  ModalIntroductionInfo_text10_5: "",
  ModalIntroductionInfo_text10_6: "",
  ModalIntroductionInfo_text10_7: "",
  ModalIntroductionInfo_text10_7_1: "",
  ModalIntroductionInfo_text10_7_2: "",
  ModalIntroductionInfo_text10_8: "",
  ModalIntroductionInfo_text10_9: "",
  ModalIntroductionInfo_text10_10: "",
  ModalIntroductionInfo_text10_11: "",
  ModalIntroductionInfo_text11: "",
  ModalIntroductionInfo_text11_1: "",
  ModalIntroductionInfo_text12: "",
  ModalIntroductionInfo_text13_1: "",
  ModalIntroductionInfo_text13_2: "",
  ModalIntroductionInfo_text13_3: "",
  ModalIntroductionInfo_text14: "",
  ModalIntroductionInfo_text14_1: "",
  ModalIntroductionInfo_text14_2: "",
  ModalIntroductionInfo_text14_3: "",
  TaskButtons_btnVideo: "",
  button_share: "",
  button_go: "",
  button_watch: "",
  cool_instruction: "",
  youtube_1: "",
  youtube_2: "",
  youtube_3: "",
  youtube_4: "",
  youtube_5: "",
  ButtonShareWithAFriendText: "",
  ModalIntroductionInfo_text_15: "",
  ModalIntroductionInfo_text15_1: "",
  ModalIntroductionInfo_text16: "",
  ModalIntroductionInfo_text16_1: "",
  ModalIntroductionInfo_text16_2: "",
  ModalIntroductionInfo_text16_3: "",
  ModalIntroductionInfo_text16_4: "",
  ModalIntroductionInfo_text16_5: "",
  ModalIntroductionInfo_text17: "",
  ModalIntroductionInfo_text18: "",
  ModalIntroductionInfo_text19: "",
  ModalIntroductionInfo_text20: "",
  ModalIntroductionInfo_text21: "",
  ModalIntroductionInfo_text22: "",
  ModalIntroductionInfo_text23: "",
  ModalIntroductionInfo_text24: "",
  toastifyFunction_error: "",
  ToastifyFunction_isNotTelegramUser: "",
  ErrorEndedLives_text: "",
  progressTittle: "",
  trainee_1: "",
  trainee_2: "",
  trainee_3: "",
  trainee_4: "",
  trainee_5: "",
  trainee_6: "",
  trainee_level: "",
  junior_1: "",
  junior_2: "",
  junior_3: "",
  junior_4: "",
  junior_5: "",
  junior_level: "",
  lifeWithdrawalNotification1: "",
  lifeWithdrawalNotification2: "",
  lifeWithdrawalNotification3: "",
  ModalShareWithFriend_title: "",
  ModalShareWithFriend_mainText: "",
  ModalShareWithFriend_mainText2_1: "",
  ModalShareWithFriend_mainText2_2: "",
  ModalShareWithFriend_refText1: "",
  ModalShareWithFriend_refText2: "",
  ModalInvitedFriend_text: "",
  ModalRefInvited_text: "",
  LeftSidePanel_video: "",
  video: "",
  taskSuccessesNotification1: "",
  taskSuccessesNotification2: "",
  taskSuccessesNotification3: "",
  taskSuccessesNotification4: "",
  taskSuccessesNotification5: "",
  taskSuccessesNotification6: "",
  taskSuccessesNotification7: "",
  taskSuccessesNotification8: "",
  taskSuccessesNotification9: "",
  taskSuccessesNotification10: "",
  congrats_preTrainee_1: "",
  congrats_preTrainee_2: "",
  congrats_preTrainee_3: "",
  congrats_preJunior_1: "",
  congrats_preJunior_2: "",
  congrats_preJunior_3: "",
};
