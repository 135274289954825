import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import MainPage from "../pages/MainPage/MainPage";
import routes from "../routes/routes";
import { IntlProvider } from "react-intl";
import lodashDebounce from "lodash.debounce";
import Context from "../context/context";
import localMessages from "../languages";

class App extends Component {
  static contextType = Context;

  state = {
    // ширина экрана пользователя
    clientWidth: window.innerWidth, // минимальное 900
  };

  componentDidMount() {
    window.addEventListener(
      "resize",
      lodashDebounce(this.updateDimensions, 200)
    );
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({
      clientWidth: window.innerWidth,
    });
  };

  render() {
    const { clientWidth } = this.state;
    const { lang } = this.context;
    return (
      <IntlProvider locale={lang} messages={localMessages[lang]}>
        <BrowserRouter basename="/html-css-marathon">
          <Switch>
            <Route
              exact
              path={routes.MAIN_PAGE.path}
              render={(routeProps) => (
                <MainPage {...routeProps} clientWidth={clientWidth} />
              )}
            />
            <Redirect to="/html-css-marathon" />
          </Switch>
        </BrowserRouter>
      </IntlProvider>
    );
  }
}

export default App;
