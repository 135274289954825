import React, { Component } from "react";
import Context from "../context";

class ContextProvider extends Component {
  state = {
    lang: "ru",
  };

  handleChangeLang = (value) => {
    this.setState({ lang: value });
  };

  render() {
    return (
      <Context.Provider value={{ lang: this.state.lang }}>
        {this.props.children}
      </Context.Provider>
    );
  }
}
export default ContextProvider;
